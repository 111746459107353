<template>
  <div class="card">
    <div class="card-header border-0 pt-6">
      <div class="card-title">
        <div class="d-flex align-items-center position-relative my-1">
          <input
            type="text"
            v-model="searchParams.search"
            v-on:keyup.enter="handleSearch"
            class="form-control form-control-solid w-250px me-2"
            :placeholder="packageConstants.SHIPPING_PACKAGE_PLACEHOLDER"
          />
        </div>
        <div class="d-flex align-items-center position-relative my-1 w-250px me-2">
          <el-select filterable v-model="searchParams.product_id" @change="setPackageDetails()" :placeholder="generalConstants.SELECTPRODUCT" size="large">
            <el-option :label="valueProduct.name" :value="valueProduct.product_id" :key="keyProduct" v-for="(valueProduct, keyProduct) in formData.product_list"></el-option>
          </el-select>
        </div>
        <el-tooltip class="box-item" effect="dark" :content="generalConstants.SEARCH" placement="top">
          <button type="button" @click.prevent="handleSearch" class="btn btn-icon btn-light btn-sm me-2"><i class="bi bi-search"></i></button>
        </el-tooltip>
        <el-tooltip class="box-item" effect="dark" :content="generalConstants.RESET" placement="top">
          <button type="button" @click.prevent="resetSearch" class="btn btn-icon btn-light btn-sm"><i class="bi bi-arrow-counterclockwise"></i></button>
        </el-tooltip>
      </div>
      <div class="card-toolbar" v-if="permission.isimportAllowed || permission.isimportHistoryAllowed">
        <a href="javascript:void()" class="btn btn-purple" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
          <i class="bi bi-file-earmark-arrow-up fs-4"></i>
          {{packageConstants.IMPORT_PACKAGES}}
          <span class="svg-icon svg-icon-5 m-0">
            <inline-svg src="/media/icons/duotune/arrows/arr072.svg" />
          </span>
        </a>
        <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-purple fw-bold py-4 w-300px fs-6" data-kt-menu="true">
        <div class="menu-item px-5">
          <a href="javascript:void(0);" v-if="permission.isimportAllowed" class="menu-link px-5" @click.stop="$router.push({ name: 'import-packages' })"><i class="bi bi-file-earmark-arrow-up fs-4"></i>&nbsp; {{packageConstants.IMPORT_PACKAGES}}</a>
        </div>
        <div class="menu-item px-5">
          <a href="javascript:void(0);" v-if="permission.isimportHistoryAllowed" class="menu-link px-5" @click.stop="$router.push({ name: 'import-packages-history' })"><i class="bi bi-clock-history fs-4"></i>&nbsp; {{packageConstants.IMPORT_PACKAGES_HISTORY}}</a>
        </div>
      </div>
      </div>
    </div>
    <div class="card-body pt-0">      
      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :rows-per-page="searchParams.recordsPerPage"
        :empty-table-text="noDataFound"
        :loading="loading"
        :current-page="1"
        :enable-items-per-page-dropdown="true"
        :total="total"
        ref="updateProps"
        @current-change="handleCurrentChange"
        @items-per-page-change="handleSizeChange"
        :order="searchParams.sortDesc"
        @sort="handleSortChange"
      >
        <template v-slot:cell-id="{ row: data }">
          {{ data }}
        </template>
        <template v-slot:cell-product="{ row: data }">
          <div v-if="permission.isProductViewAllowed">
            <a href="javascript:void(0);" class="menu-link px-3" @click="open('products/view/' + data.product_id)">{{ data.get_product.name }}</a>
          </div>
          <div v-else>
            {{ data.get_product.name }}
          </div>
        </template>
        <template v-slot:cell-product_sku_id="{ row: data }">
          <span class="sku-font">{{ data.sku }}</span>
        </template>
        <template v-slot:cell-sku_type="{ row: data }">
          {{ data.sku_type }}
        </template>
        <template v-slot:cell-internalPackage="{ row: data }">
          <div v-if="data.internalPackage">
            <el-tooltip class="box-item" effect="dark" :content="data.internalPackageDetails" placement="top" raw-content>
              {{ data.internalPackage }}
            </el-tooltip>
          </div>
          <div v-else>-</div>
        </template>
        <template v-slot:cell-externalPackage="{ row: data }">
          <div v-if="data.externalPackage">
            <el-tooltip class="box-item" effect="dark" :content="data.externalPackageDetails" placement="top" raw-content>
              {{ data.externalPackage }}
            </el-tooltip>
          </div>
          <div v-else>-</div>
        </template>
        <template v-slot:cell-package_status="{ row: data }">
          <span v-if="data.shipping_package != null">
            <el-switch @input="updateStatus(data.shipping_package.shipping_package_id, data.package_status)" v-model="data.package_status" class="ml-2" active-color="#13ce66" inactive-color="#ff4949"/>
          </span>
          <span v-else>-</span>
        </template>
        <template v-slot:cell-actions="{ row: data }">
          <div v-if="permission.isEditAllowed">
            <el-tooltip class="box-item" effect="dark" :content="packageConstants.MANAGE" placement="top">
              <button type="button" @click.prevent="manageShipping(data)" data-bs-toggle="modal" data-bs-target="#package_modal" class="btn btn-icon btn-light btn-sm me-2">
                <i class="bi bi-box2"></i>
              </button>
            </el-tooltip>
          </div>
          <div v-else>
            -
          </div>
        </template>
      </Datatable>
    </div>
    <div v-if="permission.isEditAllowed">
      <AddEditShippingPackageModal
        :form-data="formData"
        @setPackageDetails="setPackageDetails"
      ></AddEditShippingPackageModal>
    </div>
  </div>
</template>
<script>
import { ref, reactive, onMounted } from 'vue';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { useRoute, useRouter } from "vue-router";
import { notificationFire } from "@/composable/notification.js";
import AddEditShippingPackageModal from "@/components/modals/forms/AddEditShippingPackageModal.vue";
import { formatText } from "@/core/helpers/common";
import globalConstant from "@/core/data/globalConstant.js";
import { loggedInUserRoles, loggedInUserPermissions } from "@/composable/get-roles.js";
import { Actions } from "@/store/enums/RememberSearchEnums";
import { useStore } from "vuex";

export default {
  name: "shipping-package-listing",
  components: {
    Datatable,
    AddEditShippingPackageModal
  },
  setup() {
    let total = ref(0);
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const loading = ref(false);
    const tableData = ref([]);
    const generalConstants = globalConstant.general;
    const packageConstants = globalConstant.package;
    const productConstants = globalConstant.product;
    const noDataFound = ref(generalConstants.LOADING);
    const updateProps = ref(null);
    const moduleName = ref((route.meta.module) ? route.meta.module : '');
    const searchData = ref(store.getters.getShippingPackageSearch(moduleName.value));
    const searchParams = reactive({
      search: searchData.value.search,
      recordsPerPage: store.getters.getPerPage(moduleName.value),
      pageNub: store.getters.getPageNumber(moduleName.value),
      product_id: searchData.value.productId,
      sortBy: "product_sku_id",
      sortDesc: "desc"
    });
    const permission = reactive({
      isEditAllowed: false,
      isimportAllowed: false,
      isProductViewAllowed: false,
      isimportHistoryAllowed: false
    })
    //Set Table header data
    const tableHeader = ref([
      {
        name: generalConstants.SRNO,
        key: "index",
        sortable: false,
        width: "w-10px",
      },
      {
        name: productConstants.PRODUCT_NAME,
        key: "product",
        sortable: false,
        width: "w-150px",
      },
      {
        name: generalConstants.SKU,
        key: "product_sku_id",
        sortable: true,
        width: "w-150px",
      },
      {
        name: generalConstants.TYPE,
        key: "sku_type",
        width: "w-200px",
        sortable: false,
      },
      {
        name: packageConstants.INTERNAL_PACKAGE,
        key: "internalPackage",
        width: "w-150px",
        sortable: false,
        alignment: 'text-center'
      },
      {
        name: packageConstants.EXTERNAL_PACKAGE,
        key: "externalPackage",
        width: "w-150px",
        sortable: false,
        alignment: 'text-center'
      },
      {
        name: generalConstants.STATUS,
        key: "package_status",
        width: "w-50px",
        sortable: false,
        alignment: 'text-center'
      },
      {
        name: generalConstants.ACTIONS,
        key: "actions",
        width: "w-100px",
        sortable: false,
        alignment: 'text-center'
      },
    ]);

    //Page size change event
    const handleSizeChange = (number) => {
      store.dispatch(Actions.PER_PAGE, {'module':moduleName.value,'value':number});
      searchParams.recordsPerPage = store.getters.getPerPage(moduleName.value);
      handleCurrentChange(1);
    };

    //Handle search event
    const handleCurrentChange = (number) => {
      store.dispatch(Actions.PAGE_NUMBER, {'module':moduleName.value,'value':number});
      searchParams.pageNub = store.getters.getPageNumber(moduleName.value);
      setPackageDetails();
    };

    //Handle search event
    const handleSearch = () => {
      rememberSearch();
      handleCurrentChange(1);
    };

    //Handle sort change event
    const handleSortChange = (obj) => {
      searchParams.sortBy = obj.columnName;
      searchParams.sortDesc = obj.order;
      setPackageDetails();
    }

    //Reset search value
    const resetSearch = () => {
      if (searchParams.search.length > 0 || searchParams.product_id > 0) {
        searchParams.search = "";
        searchParams.product_id = "";
        rememberSearch();
        setPackageDetails();
      }
    };

    //Prepare Shipping package form data
    const manageShipping = (skuData) => {
      formData.product_id = skuData.product_id;
      formData.product_sku_id = skuData.product_sku_id;
      formData.product_sku = (skuData.modify_sku != '') ? skuData.modify_sku : skuData.sku;
      formData.shipping_package_id = (skuData.shipping_package != null) ? skuData.shipping_package.shipping_package_id : '';
      formData.shipping_package_status = (skuData.shipping_package != null) ? skuData.package_status : true;
      formData.internal_package_id = (skuData.shipping_package != null && skuData.shipping_package.internal_package != null) ? skuData.shipping_package.internal_package_id : '';
      formData.external_package_id = (skuData.shipping_package != null && skuData.shipping_package.external_package != null) ? skuData.shipping_package.external_package_id : '';
    }

    //Form data for List & Submit
    const formData = reactive({
      product_list: [],
      product_id: '',
      product_sku_id: 0,
      product_sku: '',
      shipping_package_id: '',
      internal_package_id: '',
      external_package_id: '',
      shipping_package_status: true
    });

    //Get active products details
    const getProductDetails = async() => {
      await ApiService.query("get-active-products")
      .then(({ data }) => {
        if(data.data) {
          formData.product_list = data.data;
        }
      })
      .catch((error) => {
        let message='';
        if (typeof (error.response) !== 'undefined') {
          message = error.response.data.message;
        } else if (typeof (error.message) !== 'undefined') {
          message = error.message;
        } else {
          message = generalConstants.NOTIFY_WRONG_MESSAGE;
        }
        notificationFire(message, generalConstants.NOTIFY_ERROR);
      });
    }

    //Get SKU-wise Shipping package data
    const setPackageDetails = async() => {
      loading.value = true;
      await ApiService.query("get-all-products-sku", { params: searchParams })
      .then(({ data }) => {
        if (data.data.data.length) {
          data.data.data.forEach((key) => {
            key.sku = (key.modify_sku != '') ? key.modify_sku : key.sku;
            key.sku_type = (key.setting_type == 0) ? generalConstants.FOR_SIZE : ((key.setting_type == 1) ? generalConstants.FOR_SIZE_OPTION : generalConstants.FOR_SIZE_OPTION_COMBINATION);
            key.internalPackage = (key.shipping_package != null && key.shipping_package.internal_package != null) ? key.shipping_package.internal_package.package_code : '';
            key.internalPackageDetails = (key.shipping_package != null && key.shipping_package.internal_package != null) ? 'Dimensions: ' + key.shipping_package.internal_package.package_length + 'L x ' + key.shipping_package.internal_package.package_width + 'W x ' + key.shipping_package.internal_package.package_height + 'H (' + key.shipping_package.internal_package.length_class + ')<br> Max Weight: ' + key.shipping_package.internal_package.max_weight + ' ' + key.shipping_package.internal_package.weight_class + '<br> Box Weight: ' + key.shipping_package.internal_package.box_weight + ' ' + key.shipping_package.internal_package.weight_class : '';
            key.externalPackage = (key.shipping_package != null && key.shipping_package.external_package != null) ? key.shipping_package.external_package.package_code : '';
            key.externalPackageDetails = (key.shipping_package != null && key.shipping_package.external_package != null) ? 'Dimensions: ' + key.shipping_package.external_package.package_length + 'L x ' + key.shipping_package.external_package.package_width + 'W x ' + key.shipping_package.external_package.package_height + 'H (' + key.shipping_package.external_package.length_class + ')<br> Max Weight: ' + key.shipping_package.external_package.max_weight + ' ' + key.shipping_package.external_package.weight_class + '<br> Box Weight: ' + key.shipping_package.external_package.box_weight + ' ' + key.shipping_package.external_package.weight_class : '';
            key.package_status = (key.shipping_package != null && key.shipping_package.status == 1) ? true : false;
          });
          tableData.value = data.data.data;
          total.value = data.data.total;
          updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub);
        } else {
          tableData.value = [];
          total.value = 0;
          updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub, generalConstants.NO_DATA_FOUND);
        }
        rememberSearch();
        setCurrentPageBreadcrumbs(formatText(route.name), [{ name: formatText(route.name), active: true }]);
        loading.value = false;
      })
      .catch((error) => {
        loading.value = false;
        tableData.value = [];
        let message='';
        if (typeof (error.response) !== 'undefined') {
          message = error.response.data.message;
        } else if (typeof (error.message) !== 'undefined') {
          message = error.message;
        } else {
          message = generalConstants.NOTIFY_WRONG_MESSAGE;
        }
        notificationFire(message, generalConstants.NOTIFY_ERROR);
      });
    }

    //Update shipping package status action from listing page
    const updateStatus = async (shippingPackageID, status) => {
      status == false ? (status = 0) : (status = 1);
      await ApiService.post("shipping-packages/statusUpdate", {
        id: shippingPackageID,
        status: status
      })
      .then(({ data }) => {
        if (data) {
          notificationFire(data.message, generalConstants.NOTIFY_SUCCESS);
        }
      })
      .catch(({ error }) => {
        let message='';
        if (typeof (error.response) !== 'undefined') {
          message = error.response.data.message;
        } else if (typeof (error.message) !== 'undefined') {
          message = error.message;
        } else {
          message = generalConstants.NOTIFY_WRONG_MESSAGE;
        }
        notificationFire(message, generalConstants.NOTIFY_ERROR);
      });
    };

    const rememberSearch = () => {
      let actionParams = {
          "search":searchParams.search,
          "productId":searchParams.product_id,
      }
      store.dispatch(Actions.SHIPPING_PACKAGE_SEARCH, {'module':moduleName.value,'params':actionParams});
    };

    const open = async (url) => {
      window.open(url, "_blank");
    }

    //Set breadcrumbs
    onMounted(async () => {
      setCurrentPageBreadcrumbs(formatText(route.name), [{ name: formatText(route.name), active: true }]);
      let role = loggedInUserRoles();
      let per = loggedInUserPermissions();
      if (per.includes("updateShippingPackage") || role == "Super Admin")
        permission.isEditAllowed = true;
      if (per.includes("importShippingPackage") || role == "Super Admin")
        permission.isimportAllowed = true;
      if (per.includes("viewProduct") || role == "Super Admin")
        permission.isProductViewAllowed = true;
      if (per.includes("importShippingPackageHistory") || role == "Super Admin")
        permission.isimportHistoryAllowed = true;
      if(router.currentRoute.value.params.id > 0) searchParams.product_id = Number(router.currentRoute.value.params.id);
      await getProductDetails();
      setPackageDetails();
    })
    
    return {
      route,
      formData,
      tableData,
      loading,
      getProductDetails,
      setPackageDetails,
      manageShipping,
      updateStatus,
      handleSizeChange,
      handleCurrentChange,
      total,
      handleSearch,
      resetSearch,
      searchParams,
      generalConstants,
      packageConstants,
      tableHeader,
      noDataFound,
      updateProps,
      permission,
      productConstants,
      open,
      handleSortChange
    };
  },
};
</script>
<style lang="scss">
.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(2.1em + 1.1rem + 2px);
  width: calc(2.1em + 1.1rem + 2px);
}
.btn-list {
  margin-left: 10px !important;
}
.btn.btn-purple{
  background-color: #8618cbbf;
  border-color: #8618cbbf;
  color:#ffffff;
}
.btn.btn-purple i{color:#ffffff;}
.btn.btn-purple svg path{fill:#ffffff !important;}
.btn.btn-purple:hover,.btn.btn-purple:focus,.btn.btn-purple:active{
  background-color: #6911a1bf;
  border-color: #6911a1bf;
}
.menu-state-bg-light-purple .menu-item.hover:not(.here) > .menu-link:not(.disabled):not(.active):not(.here), .menu-state-bg-light-purple .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here){
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #f5e7ff96;
  color: #8618cbbf;
}
.sku-font {
  font-family: 'Roboto Mono', monospace;
}
</style>